/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Button,
  TextField,
  Typography,
  List,
  MenuItem,
  FormControl,
  Select,
  ThemeProvider,
} from "@mui/material";
import GetAppRoundedIcon from "@mui/icons-material/GetAppRounded";
import PublishIcon from "@mui/icons-material/Publish";
import AddIcon from "@mui/icons-material/Add";
import { basicTheme } from "../../themes/customTheme";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import DeleteIcon from '@mui/icons-material/Delete';
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import InputAdornment from "@mui/material/InputAdornment";
import { ReactComponent as FilterIcon } from "../../styles/FilterIcon.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector } from "react-redux";
import Badge from "@mui/material/Badge";
import ExportButtonMenu from "../submittedForms/ExportButtonMenu";

const useStyles = makeStyles(() => ({
  search: {
    [`& fieldset`]: {
      borderRadius: 12,
    },
  },
  iconStyle: {
    cursor: "pointer",
  },
  startPos: {
    //   justifyContent: "flex-start",
    // display: "flex",
    // alignItems: "left",
    marginLeft: "230px",
    // marginRight: "1000px",
  },
  endPos: {
    justifyContent: "flex-end",
    display: "flex",
    alignItems: "right",
  },
  title: {
    [basicTheme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
    fontSize: 32,
    fontWeight: 400,
    fontStretch: "normal",
    fontStyle: "normal",
    color: "#202124",
    lineHeight: 1.25,
    letterSpacing: "normal",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
}));
const ButtonGrid = styled(Grid)(({ hasImport, hasExport }) => ({
  direction: "row",
  alignItems: "center",
  justifyContent: "flex-end",
  display: "flex",
}));
const StartGrid = styled(Grid)({
  direction: "row",
  justifyContent: "flex-start",
  display: "flex",
  alignItems: "center",
  marginRight: "10px",
  marginLeft: 80,
});

const SearchGrid = styled(Grid)({
  direction: "row",
  justifyContent: "flex-end",
  display: "flex",
  alignItems: "center",
  marginRight: "10px",
});
const MainGrid = styled(Grid)({
  display: "flex",
  justifyContent: "space-between",
});

const ButtonStyle = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "white",
  textTransform: "none",
  borderRadius: "12px",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: "100px",
  maxWidth: "200px",
  height: "48px",
});

const ImportButtonStyle = styled(Button)({
  backgroundColor: "white",
  color: "#2D6A4F",
  textTransform: "none",
  borderRadius: "12px",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: "100px",
  maxWidth: "150px",
  height: "48px",
});

const ExportButtonStyle = styled(Button)({
  backgroundColor: "white",
  color: "#2D6A4F",
  textTransform: "none",
  borderRadius: "12px",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: "100px",
  maxWidth: "150px",
  height: "48px",
  border: "1px solid #2D6A4F",
  borderColor: "#2D6A4F",
  "&:hover": {
    backgroundColor: "white",
    color: "#2D6A4F",
  },
});

const StyledFormControl = styled(FormControl)({
  backgroundColor: "#F0F8F1",
  color: "white",
  borderRadius: "10px",
});

const TextFieldStyled = styled(TextField)({
  color: "white",
  textTransform: "none",
  borderRadius: "12px",
  fontSize: "14px",
  fontWeight: "600",
  marginBottom: "1rem",
  minWidth: "250px",
  "& .MuiInputBase-input": {
    height: "20px",
  },
});

export default function TopFilterBar({
  addtext = "",
  setAddOpen = () => {},
  showListButton = false,
  itemButtonMenu = [],
  addButton = true,
  deleteButton = false,
  deleteFunc=()=>{},
  addButtonMenu = [],
  setSearchBarData = () => {},
  searchBarText,
  setSeachBarText = () => {},
  setExportData = () => {},
  handleShowFilterPopOver = () => {},
  setMenuId = () => {},
  searchBy = "Search",
  isImport = false,
  exportButton = false,
  isExport = false,
  setIsUpload = () => {},
  menuId = 1,
  loading = false,
  showFilter = false,
  filterSelected = false,
  showSearchBar = true,
  isExportButtonMenu = false,
  setExportType = "",
  selectedExport = [],
  exportType,
  handleCancelSelectExport = () => {},
  handleExport = () => {},
}) {
  const classes = useStyles();

  const [selectedTextHeading, setSelectedTextHeading] = React.useState(1);
  const [addHeadingText, setAddHeadingText] = React.useState("");
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [searchError, setSearchError] = React.useState("");
  const drawerWidth = useSelector(
    (state) => state.appBarSliceReducer.drawerWidth
  );

  const handleChangeSelectedHeading = (event) => {
    setSelectedTextHeading(event.target.value);
    setMenuId(event.target.value);
  };

  useEffect(() => {
    addButtonMenu.length !== 0 &&
      setAddHeadingText(
        addButtonMenu.filter((obj) => obj.id === menuId)[0].name
      );
  }, [menuId]);

  // useEffect to change add heading based on option selected from menu
  useEffect(() => {
    addButtonMenu.length !== 0 &&
      setAddHeadingText(
        addButtonMenu.filter((obj) => obj.id === selectedTextHeading)[0].name
      );
  }, [selectedTextHeading]);

  const stringValPatternValidation = (stringVal) => {
    return /^\s+$/.test(stringVal);
  };

  const displayDropdown = (selectedTextHeading, handleChange, menuList) => {
    return (
      <Select
        value={selectedTextHeading}
        defaultValue={selectedTextHeading}
        // label={text}
        onChange={handleChange}
        sx={{
          width: "100%",
          borderColor: "#2D6A4F",
          backgroundColor: "#F0F8F1",
          color: "#2D6A4F",
          borderRadius: "12px",
          height: "50px",
        }}
      >
        {menuList.map((menu) => {
          return (
            <MenuItem key={menu.id} value={menu.id}>
              {menu.name}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  return (
    <MainGrid
      id="top-filter-bar-main"
      container
      rowSpacing={0}
      sx={{
        marginLeft: 10,
        width: "auto",
        marginRight: 5,
        alignItems: "center",
      }}
    >
      <ThemeProvider theme={basicTheme}>
        {showListButton && (
          <Grid
            item
            xs={12}
            md={12}
            sm={3}
            lg={3}
            xl={3}
            style={{ maxWidth: "200px" }}
          >
            {displayDropdown(
              selectedTextHeading,
              handleChangeSelectedHeading,
              itemButtonMenu
            )}
          </Grid>
        )}
        {showFilter && (
          <Grid
            item
            xs={12}
            sm={1}
            md={2}
            lg={2}
            xl={1}
            style={{
              display: "flex",
              // flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Badge
              variant="dot"
              invisible={filterSelected === false}
              sx={{
                "& .MuiBadge-badge": {
                  color: "red",
                  backgroundColor: "red",
                },
              }}
            >
              <Icon
                sx={{
                  height: "48px",
                  width: "50px",
                  color: "#ACADAD",
                  stroke: "#F8F8F8",
                  cursor: "pointer"
                }}
                onClick={handleShowFilterPopOver}
              >
                <FilterIcon />
              </Icon>
            </Badge>
            <Typography
              variant="h5"
              noWrap
              component="div"
              sx={{
                color: "#78828C",
                fontFamily: "Inter",
                fontSize: "14px",
                marginLeft: "10px",
              }}
            >
              Filter
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} sm={5.5} md={4} lg={5} xl={5.5}>
          <TextFieldStyled
            fullWidth
            disabled={loading}
            label={searchBy}
            className={classes.search}
            margin="normal"
            variant="outlined"
            value={searchBarText}
            error={searchError !== ""}
            helperText={searchError}
            onInput={(e) => {
              var emptyString = stringValPatternValidation(e.target.value);
              if (emptyString) {
                setSearchError("White Spaces are not allowed");
              } else {
                setSearchError("");
              }
              setSeachBarText(e.target.value);
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter" && searchBarText.trim() !== "") {
                var tempSearchBarText = searchBarText;
                tempSearchBarText = tempSearchBarText.replace(/^\s+/g, "");
                setSearchBarData(tempSearchBarText);
              }
            }}
            onChange={(e) => {
              if (e.target.value.trim() === "")
                setSearchBarData(e.target.value.trim());
            }}
            // inputProps={{  }}
            InputProps={{
              style: { borderColor: "green" },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      if (searchBarText !== "") {
                        var text = searchBarText.replace(/^\s+|\s+$/gm, "");
                        setSearchBarData(text);
                      }
                    }}
                  >
                    <SearchRoundedIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            inputProps={{
              maxLength: 40, // Set the maximum character length to 40
            }}
          />
        </Grid>

        <Grid item xs={12} sm={5} md={6} lg={5} xl={4}>
          <ButtonGrid container spacing={1}>
            {addButton && !deleteButton && !exportType && (
              <Grid item>
                <ButtonStyle
                  variant="contained"
                  name="add"
                  onClick={() => setAddOpen(true)}
                  startIcon={
                    <AddIcon size="large" className={classes.iconStyle} />
                  }
                >
                  {addButtonMenu.length === 0 ? addtext : addHeadingText}
                </ButtonStyle>
              </Grid>
            )}
            {deleteButton && !exportType && (
              <Grid item>
                <ButtonStyle
                  variant="contained"
                  name="delete"
                  onClick={() => deleteFunc(true)}
                  startIcon={
                    <DeleteIcon size="large" className={classes.iconStyle} />
                  }
                  color="error"
                  sx={{ background: "#EB5451" }}
                >
                  Delete Selected
                </ButtonStyle>
              </Grid>
            )}
            {isImport && !exportType && (
              <Grid item style={{ marginRight: !addButton ? "40px" : "0px" }}>
                <ImportButtonStyle
                  variant="contained"
                  name="Download"
                  startIcon={<PublishIcon className={classes.iconStyle} />}
                  onClick={(event) => setIsUpload(event.currentTarget)}
                  // sx={{width:{xl:"200px",lg:"130px",md:"100px",sm:"80px"},}}
                >
                  Import
                </ImportButtonStyle>
              </Grid>
            )}
            {isExport && (
              <Grid item style={{ marginRight: !addButton ? "40px" : "0px" }}>
                <ExportButtonStyle
                  variant="contained"
                  name="Download"
                  startIcon={
                    <GetAppRoundedIcon className={classes.iconStyle} />
                  }
                  onClick={() => setExportData(true)}
                  // sx={{width:{xl:"200px",lg:"130px",md:"100px",sm:"80px"},}}
                >
                  Export
                </ExportButtonStyle>
              </Grid>
            )}
            {isExportButtonMenu && (
              <Grid item style={{ marginRight: !addButton ? "40px" : "0px" }}>
                <ExportButtonMenu
                  setExportData={setExportData}
                  setExportType={setExportType}
                  selectedExport={selectedExport}
                  handleCancelSelectExport={handleCancelSelectExport}
                  handleExport={handleExport}
                />
              </Grid>
            )}
          </ButtonGrid>
        </Grid>
      </ThemeProvider>
    </MainGrid>
  );
}
